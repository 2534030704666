<template>
  <v-container :fluid="true" class="synapsa-container">
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 synapsa-tabs">
        <v-btn
          v-for="(tab, key) in tabs"
          v-permission="tab.permissions"
          :key="key"
          :text="!tab.ids.includes(activeTab)"
          :color="tab.ids.includes(activeTab) ? 'primary' : ''"
          :outlined="tab.ids.includes(activeTab)"
          small
          class="text-capitalize synapsa-tab"
          :class="tab.ids.includes(activeTab) ? 'elevation-1' : ''"
          @click="to(tab)"
        >
          {{ $t(tab.name) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-1">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import permission from "@/directive/permission/permission.js";
export default {
  directives: { permission },
  data() {
    return {
      activeTab: "addressListsCustomAll",
      tabs: [
        {
          id: "addressListsCustomAll",
          ids: [
            "addressListsCustomAll",
            "addressListsCustomShow",
            "addressListsCustomSource",
            "addressListsCustomDestination",
          ],
          name: "dataFeeds.addresslist.custom",
          route: "/data_feeds/address_lists/custom/all",
          permissions: ["view customaddress"],
        },
        {
          id: "addressListsPredefinedAll",
          ids: [
            "addressListsPredefinedAll",
            "addressListsPredefinedShow",
            "addressListsPredefinedIPv4",
            "addressListsPredefinedIPv6",
          ],
          name: "dataFeeds.addresslist.predefined",
          route: "/data_feeds/address_lists/predefined/all",
          permissions: ["view predefinedaddress"],
        },
        {
          id: "addressListsConnection",
          ids: [
            "addressListsConnection",
          ],
          name: "dataFeeds.addresslist.connection",
          route: "/data_feeds/address_lists/connection",
          permissions: ["view customaddress"],
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeTab = newValue.name;
      },
    },
  },
  methods: {
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
  },
};
</script>
